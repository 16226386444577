import React from 'react';
import PropTypes from 'prop-types';

import Section from '../Section';
import * as Styled from './ClientsCaseStudy.styles';
import Container from '../Container';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';
import {graphql} from 'gatsby';
import {createdFormattedDate} from '../../utils/formattedDate';

const ClientsCaseStudy = ({
  title,
  slug,
  pageSlug,
  subtitle,
  services = [],
  linkLabel = 'Read more',
}) => {
  const {isDark} = useStyledDarkMode();

  let isNewCSPage = pageSlug === 'case-studies';
  const csLink = isNewCSPage ? '/' : `/${slug || 'services'}/`;

  return (
    <Section>
      <Container>
        <Section.Title
          id={slug}
          alignment="left"
          title={title}
          subtitle={subtitle ? subtitle.childMarkdownRemark.html : ''}
        />
      </Container>
      <Section.Body>
        {services && (
          <Styled.ServicesWrapper id="services">
            <Container>
              <Styled.ServiceSection>
                {services.map((service, key) => {
                  const serviceIcon =
                    service.icon && service.icon.file
                      ? service.icon.file.url
                      : null;
                  const darkServiceIcon =
                    service.darkThemeIcon &&
                    service.darkThemeIcon.file
                      ? service.darkThemeIcon.file.url
                      : null;

                  return (
                    <Styled.Service className="service" key={key}>
                      <Styled.ServiceIcon>
                        {isDark ? (
                          <img
                            src={darkServiceIcon || serviceIcon}
                            style={
                              isDark && !darkServiceIcon
                                ? {filter: 'invert(1)'}
                                : {}
                            }
                            alt={service.title}
                            loading="lazy"
                          />
                        ) : (
                          <img
                            src={serviceIcon}
                            alt={service.title}
                            loading="lazy"
                            width="310"
                            height="300"
                          />
                        )}
                      </Styled.ServiceIcon>
                      <Styled.ServiceContent>
                        <Styled.ServiceDescription
                          dangerouslySetInnerHTML={{
                            __html:
                              service.shortDescription
                                .childMarkdownRemark.html,
                          }}
                        />
                        

                        <Styled.ServiceLink
                          to={`${csLink}${service.slug}`}
                          aria-label={service.slug}
                        >
                          <span>{linkLabel}</span>
                          <svg
                            width="24"
                            height="24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M19.094 11.468l-8.301-7.205a.353.353 0 00-.23-.085H8.617a.175.175 0 00-.115.307l7.695 6.68H3.34a.176.176 0 00-.176.176v1.318c0 .097.08.176.176.176h12.856l-7.695 6.68a.175.175 0 00.114.307h2.011a.169.169 0 00.114-.044l8.354-7.246a.704.704 0 000-1.064z"
                              fill="currentColor"
                            />
                          </svg>
                        </Styled.ServiceLink>
                      </Styled.ServiceContent>
                      <Styled.ServiceImage>
                        {service.serviceImage && (
                          <img
                            src={service.serviceImage.file.url}
                            alt=""
                          />
                        )}
                      </Styled.ServiceImage>
                    </Styled.Service>
                  );
                })}
              </Styled.ServiceSection>
            </Container>
          </Styled.ServicesWrapper>
        )}
      </Section.Body>
    </Section>
  );
};

export const query = graphql`
  fragment ClientsCaseStudy on ContentfulClientsSection {
    slug
    hasLink
    sectionTitle: title
    subtitle {
      subtitle
      childMarkdownRemark {
        html
      }
    }
    clients {
      title
      logo {
        file {
          url
        }
      }
    }
    services {
      title
      slug
      createdAt
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      icon {
        file {
          url
        }
      }
      serviceImage {
        file {
          url
        }
      }
      darkThemeIcon {
        file {
          url
        }
      }
    }
  }
`;

ClientsCaseStudy.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      logo: PropTypes.any,
    })
  ),
};

export default ClientsCaseStudy;
