import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Navigation.styles';
import Logo from '../Logo';

const NavigationChildMenu = ({items = []}) => {
  return (
    <Styled.ChildMenu>
      {items.map((childLink, key) => (
        <Styled.LinkWrapper key={`child-menu-${childLink.title}`}>
          {/* {childLink.to && (
            <Styled.NavigationLink to={childLink.to}>
              {childLink.title}
            </Styled.NavigationLink>
          )} */}
          <Styled.NavigationLink
            as="a"
            href={childLink.to}
            target={
              childLink.to.includes('https:') ? '_blank' : '_self'
            }
          >
            {childLink.title}
          </Styled.NavigationLink>
        </Styled.LinkWrapper>
      ))}
    </Styled.ChildMenu>
  );
};

const NavigationLink = ({link, index, onHideNavigation}) => {
  let children = null;
  if (link.childItems?.length) {
    children = (
      <>
        <span>{link.title}</span>
        <NavigationChildMenu items={link.childItems} />
      </>
    );
  } else {
    children = (
      <a
        href={link.to}
        target={link.to.includes('https:') ? '_blank' : '_self'}
      >
        {link.title}
      </a>
    );
  }

  const hasChildren = link.childItems && link.childItems.length;

  const handleHideNavigation = hasChildren
    ? () => {}
    : onHideNavigation;

  return (
    <Styled.LinkWrapper
      className={[hasChildren ? 'has-children' : ''].join(' ')}
      key={link.title}
      style={{transitionDelay: `${index * 0.1}s`}}
      onClick={handleHideNavigation}
    >
      {children}
    </Styled.LinkWrapper>
  );
};

/**
 * Navigation component to use on primary & footer menu
 * @returns
 */
const Navigation = ({
  layout = 'horizontal',
  className,
  links = [],
  onHideNavigation,
}) => {
  return (
    <Styled.Wrapper
      className={[className, `navigation-layout-${layout}`].join(' ')}
    >
      <Styled.Logo>
        <Logo />
      </Styled.Logo>
      <Styled.Links>
        {links.map((link, key) => (
          <NavigationLink
            link={link}
            key={key}
            index={key}
            onHideNavigation={onHideNavigation}
          />
        ))}
      </Styled.Links>
    </Styled.Wrapper>
  );
};

Navigation.propTypes = {
  layout: PropTypes.oneOf(['horizontal', 'vertical']),
  className: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      isExternal: PropTypes.bool,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
          isExternal: PropTypes.bool,
        })
      ),
    })
  ),
};

export default Navigation;
