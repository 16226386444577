import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SectionTitle.styles';
import Container from '../Container';

const SectionTitle = ({
  title,
  subtitle,
  id = '',
  alignment = 'left',
}) => {
  return (
    <Styled.Wrapper
      className={[`section-title-alignment-${alignment}`]}
      id={id}
    >
      {title.length > 1 ? (
        <Styled.Title>
          <span>{title}</span>
        </Styled.Title>
      ) : (
        ''
      )}
      {subtitle ? (
        <Styled.Subtitle>
          <span dangerouslySetInnerHTML={{__html: subtitle}} />
        </Styled.Subtitle>
      ) : (
        ''
      )}
    </Styled.Wrapper>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['center', 'left']),
};

export default SectionTitle;
