import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const ServicesWrapper = styled.div`
  border-top: 1px solid ${({theme}) => theme.colors.lightGray};
  margin-bottom: 40px;
`;

export const ServiceSection = styled.div`
  ${media.tablet} {
    padding: 120px 0;
  }
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 0;
  column-gap: 24px;
  color: ${({theme}) => theme.colors.textColor};
  &:not(:last-child) {
    border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
  }

  ${media.tablet} {
    flex-direction: row;
  }
`;

export const ServiceIcon = styled.div`
  order: 1;
  margin-right: auto;
  margin-bottom: 20px;
  img {
    max-width: 140px;
    max-height: 40px;
  }

  ${media.tablet} {
    margin-bottom: 0;
  }
`;

export const ServiceImage = styled.div`
  width: 100%;
  height: 240px;
  margin-bottom: 20px;
  order: 2;
  img {
    max-width: 100%;
    max-height: 240px;
    width: 100%;
    object-fit: cover;
  }

  ${media.tablet} {
    order: 4;
    width: 300px;
    height: 220px;
    margin-bottom: 0;

    img {
      max-width: 300px;
      max-height: 220px;
    }
  }
`;

export const ServiceContent = styled.div`
  max-width: 380px;
  order: 3;
`;

export const ServiceDescription = styled.div`
  & p {
    margin: 0;
    font-size: 20px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.textColor};
    line-height: 28px;
  }
  margin-bottom: 40px;
`;

export const SubDescription = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;


export const ServiceLink = styled(Link)`
  order: 4;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;
  text-decoration: none;
  color: ${({theme}) => theme.colors.textColor};
  transition: 0.2s;

  &:hover {
    color: ${({theme}) => theme.colors.accent};
  }
`;
