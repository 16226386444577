import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './CtaSection.styles';
import Container from '../Container';
import Button from '../Button';
import {graphql} from 'gatsby';

const CtaSection = ({title, actionLink, actionText}) => {
  return (
    <Container>
      <Styled.Wrapper>
        {title && <CtaSection.Title>{title}</CtaSection.Title>}
        {actionLink && (
          <Styled.CTA>
            <Button link={actionLink}>{actionText}</Button>
          </Styled.CTA>
        )}
      </Styled.Wrapper>
    </Container>
  );
};

CtaSection.Title = Styled.Title;

export const query = graphql`
  fragment CtaSection on ContentfulCtaSection {
    ctaSectionTitle: title
    actionLink
    actionText
  }
`;

CtaSection.propTypes = {
  title: PropTypes.string,
  actionLink: PropTypes.string,
  actionText: PropTypes.string,
};

export default CtaSection;
