import React from 'react';

import Container from '../Container';
import * as Styled from './BlogsSection.styles';
import {graphql} from 'gatsby';
import SectionHeading from '../SectionHeading/SectionHeading';
import Button from '../Button';

const BlogsSection = ({
  slug,
  title,
  subtitle,
  cards,
  hasLink,
  linkLabel = 'Learn more',
}) => {
  return (
    <Styled.Wrapper>
      <Container>
        <SectionHeading
          title={title}
          subtitle={subtitle}
          wrapperStyle={{marginTop: '0', marginBottom: '40px'}}
        />

        <Styled.Content>
          {cards.map((card, id) => (
            <Styled.Card key={id}>
              <Styled.CardImage>
                <img
                  src={card.image.file.url}
                  alt={card.title}
                  loading="lazy"
                />
              </Styled.CardImage>
              <Styled.CardTitle>{card.title}</Styled.CardTitle>

              <Styled.CardText>
                {card.shortDescription.shortDescription}
              </Styled.CardText>

            

              {card.slug.includes('/ai') ? (
                <Styled.CardLink>
                  <Button
                    link={card.slug}
                    linkLabel={card.title}
                  >
                    <span>{linkLabel}</span>
                  </Button>
                </Styled.CardLink>
              ) : (
                ''
              )}
            </Styled.Card>
          ))}
        </Styled.Content>
      </Container>
    </Styled.Wrapper>
  );
};

export const query = graphql`
  fragment BlogsSection on ContentfulInsightsSection {
    sectionTitle: title
    type
    insightsWithLink
    items {
      title
      slug
      shortDescription {
        shortDescription
      }
      image {
        file {
          url
        }
      }
      darkModeImage {
        file {
          url
        }
      }
    }
  }
`;

export default BlogsSection;
