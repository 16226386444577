import React from 'react';

import Header from '../Header';
import Footer from '../Footer';
import Seo from '../Seo';
import * as Styled from './Layout.styles';

const Layout = ({
  title = 'AppliedLabs',
  // headerNavigation,
  footerNavigation,
  template,
  children,
  companyAddress,
  metaContent,
  slug
}) => {

  const siteUrl = process.env.URL || `https://www.appliedlabs.io`;
  
  return (
    <Styled.Wrapper
      className={[`layout-template-${template || ''}`].join(' ')}
    >
      <Seo title={title} metaContent={metaContent} siteUrl={siteUrl} slug={slug} />
      <Header />
      <Styled.Main>{children}</Styled.Main>
      <Footer
        navigation={footerNavigation}
        companyAddress={companyAddress}
      />
    </Styled.Wrapper>
  );
};

export default Layout;
