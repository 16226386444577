import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SectionHeading.styles';
import Container from '../Container';

const SectionHeading = ({
  title,
  subtitle,
  id = '',
  alignment = 'left',
  wrapperStyle,
}) => {
  return (
    <Styled.Wrapper
      className={[`section-title-alignment-${alignment}`]}
      style={wrapperStyle}
      id={id}
    >
      <Container>
        <Styled.Title>{title}</Styled.Title>

        {subtitle ? (
          <Styled.Subtitle
            dangerouslySetInnerHTML={{
              __html: subtitle
                ? subtitle.childMarkdownRemark.html
                : '',
            }}
          />
        ) : (
          ''
        )}
      </Container>
    </Styled.Wrapper>
  );
};

SectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['center', 'left']),
};

export default SectionHeading;
