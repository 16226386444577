import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const Wrapper = styled.div`
  margin: 80px 0 0 0;
`;

export const TestimonialList = styled.div``;

export const Testimonial = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  color: ${({theme}) => theme.colors.textColor};
  flex-direction: column-reverse;
  margin-bottom: 16px;

  ${media.tablet} {
    flex-direction: row;
  }
`;
export const TestimonialClient = styled.div`
  flex: 0 1 100%;
  text-align: center;
  display: flex;
  flex-direction: column;

  border-right: none;
  width: 100%;

  ${media.tablet} {
    flex: 0 1 33%;
  }
`;

export const TestimonialClientLogo = styled.div`
  margin: 14px 0;
  padding: 12px 0;

  border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};

  img {
    .theme-dark & {
      filter: invert(0.6);
    }
  }
`;

export const TestimonialClientInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TestimonialClientName = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`;
export const TestimonialClientRole = styled.p`
  margin-top: 8px;
  margin-bottom: 20px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  p {
    margin: 0;
  }
`;

export const TestimonialClientComment = styled.div`
  flex: 1 1 66%;
  padding: 24px;
  font-size: 20px;
  font-weight: 300;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
  border-left: none;

  p {
    margin: 0;
  }

  ${media.tablet} {
    border: none;
    border-left: 1px solid ${({theme}) => theme.colors.lightGray};
  }
`;
