import React from 'react';
import CardsSection from '../CardsSection';

import ClientsSection from '../ClientsSection';
import InsightsSection from '../InsightsSection';
import TestimonialsSection from '../TestimonialsSection';
import TestimonialsList from '../TestimonialsList';
import Section from '../Section';
import SectionHeading from '../SectionHeading/SectionHeading';
import SectionContent from '../SectionContent';
import BlogsSection from '../BlogsSection';
import ClientsInsightsSection from '../ClientsInsightsSection';
import ClientsCaseStudy from '../ClientsCaseStudy';
import SectionFact from '../SectionFact';

const SectionMap = {
  'section-title': ({sectionData}) => (
    <Section className="text-lg">
      <Section.Title
        title={sectionData.sectionTitle}
        subtitle={
          sectionData.subtitle
            ? sectionData.subtitle.childMarkdownRemark.html
            : ''
        }
        alignment="center"
      />
    </Section>
  ),
  'section-fact': ({sectionData}) => (
    <Section>
      <SectionFact
        title={sectionData.sectionTitle}
        content={sectionData.subtitle}
      />
    </Section>
  ),
  'who-we-are': ({sectionData}) => (
    <Section
      objectFit="contain"
      backgroundImage={
        sectionData.backgroundImage
          ? sectionData.backgroundImage.file.url
          : null
      }
    >
      <Section.Title
        title={sectionData.sectionTitle}
        subtitle={
          sectionData.subtitle
            ? sectionData.subtitle.childMarkdownRemark.html
            : ''
        }
      />
    </Section>
  ),
  'our-team': ({sectionData}) => (
    <div id="company" style={{marginTop: '27px'}}>
      <Section
        className="section-with-image"
        slug={sectionData.slug}
        backgroundImage={
          sectionData.backgroundImage
            ? sectionData.backgroundImage.file.url
            : null
        }
      >
        <Section.Title
          title={sectionData.sectionTitle}
          subtitle={
            sectionData.subtitle
              ? sectionData.subtitle.childMarkdownRemark.html
              : ''
          }
        />
      </Section>
    </div>
  ),
  heading: ({sectionData}) => (
    <div id="heading-section">
      <SectionHeading
        title={sectionData.sectionTitle}
        subtitle={sectionData.subtitle}
      />
    </div>
  ),
  content: ({sectionData}) => (
    <div id="section-content">
      <SectionContent
        title={sectionData.sectionTitle}
        content={sectionData.subtitle}
      />
    </div>
  ),
  banner: ({sectionData}) => (
    <div id="banner-image">
      <Section
        className="section-with-image"
        slug={sectionData.slug}
        backgroundImage={
          sectionData.backgroundImage
            ? sectionData.backgroundImage.file.url
            : null
        }
        darkThemeBackgroundImage={
          sectionData.darkThemeBackgroundImage.file.url
        }
      ></Section>
    </div>
  ),
  clients: ({sectionData, pageSlug}) => {
    return (
      <ClientsSection
        clients={sectionData.clients}
        services={sectionData.services}
        title={sectionData.sectionTitle}
        subtitle={sectionData.subtitle}
        slug={sectionData.slug}
        hasLink={sectionData.hasLink}
        pageSlug={pageSlug}
        linkLabel={sectionData.linkLabel}
      />
    );
  },
  'clients-insights': ({sectionData, pageSlug}) => {
    return (
      <ClientsInsightsSection
        clients={sectionData.clients}
        services={sectionData.services}
        title={sectionData.sectionTitle}
        subtitle={sectionData.subtitle}
        slug={sectionData.slug}
        hasLink={sectionData.hasLink}
        pageSlug={pageSlug}
        linkLabel={sectionData.linkLabel}
        createdAt={sectionData.createdAt}
      />
    );
  },
  'clients-case-study': ({sectionData, pageSlug}) => {
    return (
      <ClientsCaseStudy
        clients={sectionData.clients}
        services={sectionData.services}
        title={sectionData.sectionTitle}
        subtitle={sectionData.subtitle}
        slug={sectionData.slug}
        hasLink={sectionData.hasLink}
        pageSlug={pageSlug}
        linkLabel={sectionData.linkLabel}
        createdAt={sectionData.createdAt}
      />
    );
  },
  testimonials: ({sectionData}) => {
    return (
      <TestimonialsSection
        testimonials={sectionData.testimonials}
        title={sectionData.sectionTitle}
        subtitle={sectionData.subtitle}
        slug={sectionData.slug}
      />
    );
  },
  'testimonials-list': ({sectionData}) => {
    return (
      <TestimonialsList
        testimonials={sectionData.testimonials}
        title={sectionData.sectionTitle}
        subtitle={sectionData.subtitle}
        slug={sectionData.slug}
      />
    );
  },
  insights: ({sectionData}) => (
    <InsightsSection
      title={sectionData.sectionTitle}
      subtitle={sectionData.subtitle}
      image={sectionData.image}
      insights={sectionData.blogs}
      slug={sectionData.slug}
    />
  ),
  'insights-card': ({sectionData}) => (
    <CardsSection
      title={sectionData.sectionTitle}
      subtitle={sectionData.subtitle}
      cards={sectionData.items}
      slug={sectionData.slug}
      hasLink={sectionData.insightsWithLink}
    />
  ),
  'insights-blog': ({sectionData}) => (
    <BlogsSection
      title={sectionData.sectionTitle}
      subtitle={sectionData.subtitle}
      cards={sectionData.items}
      slug={sectionData.slug}
      hasLink={sectionData.insightsWithLink}
    />
  ),
};

const SectionMapper = ({type, sectionData, pageSlug}) => {
  const Component = SectionMap[type];

  return <Component sectionData={sectionData} pageSlug={pageSlug} />;
};

export default SectionMapper;
