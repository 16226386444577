import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.header`
  padding-top: 80px;
  padding-bottom: 40px;

  ${media.tablet} {
    padding-top: 100px;
  }
`;

export const Title = styled.h3`
  font-family: 'Noto Serif';
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 32px;
  color: ${({theme}) => theme.colors.textColor};
  margin: 0px;
  transition: color 0.2s;

  ${media.tablet} {
    font-size: 32px;
    line-height: 40px;
  }

  ${media.desktop} {
    padding: 0;
  }
`;

export const Content = styled.div`
  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
    margin: 0;
    margin-bottom: 20px;
  }

  margin-top: 24px;

  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;
  text-align: left;

  ${media.tablet} {
    font-size: 20px;
    line-height: 28px;
    padding: 0 10px;
  }

  ${media.desktop} {
    margin-bottom: 20px;
    padding: 0;
  }
`;
