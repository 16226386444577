const mapPageTypeToUrlPrefix = {
  ContentfulPage: '',
  ContentfulService: 'services/',
  ContentfulInsight: 'insights/',
  ContentfulConnector: 'connectors/',
};

export const buildNavigationMenu = (contentfulMenuItems) => {
  const result = (contentfulMenuItems || []).map((item) => ({
    title: item.title,
    to: item.page
      ? `/${mapPageTypeToUrlPrefix[item.page.__typename]}${
          item.page.slug
        }`
      : item.link,
    link: item.link,
    childItems: item.childItems
      ? item.childItems.map((_item) => ({
          title: _item.title,
          to: _item.page
            ? `/${mapPageTypeToUrlPrefix[_item.page.__typename]}${
                _item.page.slug
              }`
            : _item.link,
          link: _item.link,
        }))
      : [],
  }));
  return result;
};
