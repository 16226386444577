import styled from 'styled-components';
import media from '../../shared/media';
import Link from 'gatsby-link';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.bodyBackgroundColor};
  z-index: 1000;
  padding: 41px 36px;
  opacity: 0;
  pointer-events: none;
  transition: 0.2s;

  ${media.tablet} {
    opacity: 1;
    pointer-events: all;
  }

  .header-navigation-expanded & {
    opacity: 1;
    pointer-events: all;
  }

  ${media.tablet} {
    position: static;
    width: auto;
    height: auto;
    background: none;
    z-index: auto;
    padding: 0;
  }
`;

export const Links = styled.ul`
  ${media.tablet} {
    display: flex;
    text-align: none;
  }
  text-align: start;
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const ChildMenu = styled.ul`
  width: max-content;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  list-style: none;
  padding: 0;
  margin: 0;
  opacity: 0;
  /* transform: translate(-30%, -20px); */
  pointer-events: none;
  transition: 0.2s;
  transition-delay: 0.2s;
  z-index: -1;

  .theme-dark & {
    background-color: #131313;
  }

  @media (max-width: 500px) {
    /* transform: translate(-70px, -10px); */
    position: relative;
    height: 0;
    /* margin-left: 130px; */
    margin-top: 15px;
    width: 85%;
  }
`;

export const LinkWrapper = styled.li`
  position: relative;
  ${media.tablet} {
    font-size: 1rem;
    opacity: 1;
    transform: none;
    font-weight: 400;
  }
  & + & {
    margin-top: 1.5em;
    ${media.tablet} {
      margin-left: 20px;
      margin-top: 0;
    }
  }
  &.has-children {
    li {
      margin: 0;
    }
    a:after,
    span:after {
      display: none;
    }
  }
  font-size: 1.6rem;
  font-weight: 300;
  color: ${({theme}) => theme.colors.textColor};
  line-height: 1.125rem;
  letter-spacing: -0.53px;
  transform: translateY(20px);
  opacity: 0;
  transition: 0.2s;
  font-weight: 400;

  &.has-children:hover ${ChildMenu} {
    opacity: 1;
    /* transform: translate(-30%, 0); */
    pointer-events: all;
    transition-delay: 0;
    z-index: 100;

    @media (max-width: 500px) {
      height: 100%;
      background-color: #fff;
      /* box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.07); */

      .theme-dark & {
        background-color: transparent;
        color: ${({theme}) => theme.colors.textColor};
      }
    }
  }

  .header-navigation-expanded & {
    transform: translateY(0);
    opacity: 1;
  }

  &:hover {
    /* color: ${({theme}) => theme.colors.accent}; */
  }

  span {
    user-select: none;
  }

  a,
  span {
    display: inline-block;
    position: relative;
    text-decoration: none;
    cursor: pointer;
    color: inherit;

    .has-children & {
      display: block;
      text-align: left !important;
      padding: 14px 20px;
      min-width: 10em;
      line-height: 26px;
      font-weight: 300;
    }

    .has-children &:hover {
      background-color: ${({theme}) => theme.colors.lightGray};

      @media (max-width: 500px) {
        background-color: transparent;
      }
    }

    &:after {
      content: '';
      display: block;
      position: relative;
      width: 100%;
      height: 3px;
      margin-top: 5px;
      transform: scale(0, 1);
      transform-origin: center;
      background-color: ${({theme}) => theme.colors.accent};
      transition: 0.3s ease-in;
    }

    &:hover:after {
      transform: scale(1, 1);
    }
  }
`;

export const Logo = styled.div`
  margin-bottom: 60px;
  transition: 0.2s;
  transform: translateX(0);

  .header-navigation-expanded & {
    transform: translateX(30px);
  }

  ${media.tablet} {
    display: none;
  }
`;

export const NavigationLink = styled(Link)``;
