import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const Wrapper = styled.div`
  padding-top: 80px;

  ${media.tablet} {
    padding-top: 100px;
  }

  ${media.desktop} {
    padding-top: 120px;
  }
`;

export const Title = styled.h2`
  font-family: 'Noto Serif';
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0px;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;
  text-align: left;

  ${media.tablet} {
    font-size: 40px;
    line-height: 48px;
    padding: 0 20px;
  }

  ${media.desktop} {
    padding: 0;
  }
`;

export const TestimonialList = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-top: 24px;

  ${media.tablet} {
    flex-direction: row;
    column-gap: 24px;
    margin-top: 32px;
  }
`;

export const Testimonial = styled.div`
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  padding: 24px 24px 16px 24px;
  display: flex;
  flex-direction: column;
  color: ${({theme}) => theme.colors.textColor};

  ${media.tablet} {
  }
`;
export const TestimonialClient = styled.div`
  flex: 0 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const TestimonialClientLogo = styled.div`
  height: 28px;

  img {
    height: 100%;
    width: auto !important;

    .theme-dark & {
      filter: invert(0.6);
    }
  }
`;

export const TestimonialClientInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TestimonialClientName = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
`;
export const TestimonialClientRole = styled.p`
  margin-top: 8px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  p {
    margin: 0;
  }
`;

export const TestimonialClientComment = styled.div`
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
  ${media.tablet} {
    border-bottom: none;
  }
`;
