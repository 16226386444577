import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SectionFact.styles';
import Container from '../Container';

const SectionFact = ({title, content, id = '', wrapperStyle}) => {
  return (
    <Styled.Wrapper style={wrapperStyle} id={id}>
      <Container>
        <Styled.FactWrapper>
          <Styled.FactTitle>{title}</Styled.FactTitle>
          <Styled.FactContent
            dangerouslySetInnerHTML={{
              __html: content ? content.childMarkdownRemark.html : '',
            }}
          />
        </Styled.FactWrapper>
      </Container>
    </Styled.Wrapper>
  );
};

SectionFact.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default SectionFact;
