import styled from 'styled-components';
import media from '../../shared/media';
import Link from 'gatsby-link';

export const Wrapper = styled.div`
  padding-top: 52px;

  ${media.tablet} {
    padding-top: 80px;
  }

  ${media.desktop} {
    padding-top: 112px;
  }
`;

export const Title = styled.h2`
  font-family: 'Noto Serif';
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  letter-spacing: 0;
  margin: 0px;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;
  text-align: left;

  ${media.tablet} {
    font-size: 40px;
    line-height: 48px;
    padding: 0 20px;
  }

  ${media.desktop} {
    padding: 0;
  }
`;

export const Subtitle = styled.div`
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.5px;
  line-height: 28px;
  color: ${({theme}) => theme.colors.textColor};
  margin-bottom: 40px;
  transition: color 0.2s;
  text-align: left;
  line-height: 32px;

  ${media.tablet} {
    font-size: 24px;
    line-height: 32px;
  }

  ${media.desktop} {
    padding: 0;
  }

  .section-with-image & {
    color: #fff;
  }

  p {
    margin: 0;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 20%;
    height: 3px;
    background-color: rgba(120, 194, 87, 0.5);
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  margin-top: 32px;

  ${media.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40px;
  }
`;

export const Card = styled.div`
  flex: 0 1 280px;
  width: 100%;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  color: ${({theme}) => theme.colors.textColor};

  ${media.tablet} {
    margin-bottom: 0;
  }
`;

export const CardImage = styled.figure`
  display: block;
  margin: 0;

  & img {
    width: 100%;
    height: 100%;
  }
`;

export const CardTitle = styled.p`
  font-family: 'Noto Serif';
  font-size: 20px;
  font-weight: 600;
  line-height: 28px;
  margin-top: 16px;
  margin-bottom: 0;
`;

export const CardText = styled.p`
  font-size: 18px;
  line-height: 24px;
  margin-top: 12px;
  font-weight: 300;
`;

export const CardLink = styled(Link)`
  display: flex;
  column-gap: 12px;
  text-decoration: none;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
  color: ${({theme}) => theme.button.textColor};

  .icon {
    position: relative;
    top: 3px;
  }
`;
