import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';
import * as Styled from './InsightsSection.styles';
import {graphql} from 'gatsby';
import SectionHeading from '../SectionHeading/SectionHeading';

const InsightsSection = ({
  title,
  subtitle,
  slug,
  image,
  insights,
}) => {
  return (
    <Styled.InsightsWrapper>
      <Container>
        <SectionHeading title={title} subtitle={subtitle} />
        <Styled.InsightsList>
          {insights?.map((insight, key) => (
            <Styled.Insight key={key}>
              <Styled.InsightImage>
                {insight.image && (
                  <img
                    src={insight.image.file.url}
                    title={insight.image.file.title}
                    alt={insight.image.file.title}
                    loading="lazy"
                  />
                )}
              </Styled.InsightImage>
              <Styled.InsightTitle>
                {insight.title}
              </Styled.InsightTitle>
              <Styled.InsightDescription>
                {insight.shortDescription.shortDescription}
              </Styled.InsightDescription>
            </Styled.Insight>
          ))}
        </Styled.InsightsList>
      </Container>
    </Styled.InsightsWrapper>
  );
};

InsightsSection.propTypes = {
  title: PropTypes.string,
  insights: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      shortDescription: PropTypes.shape({
        shortDescription: PropTypes.string,
      }),
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    })
  ),
};

export const query = graphql`
  fragment InsightsSection on ContentfulInsightsSection {
    sectionTitle: title
    subtitle {
      childMarkdownRemark {
        html
      }
    }
    type
    insightsWithLink
    items {
      title
      slug
      shortDescription {
        shortDescription
      }
      image {
        file {
          url
        }
      }
    }
  }
`;

// blogs {
//   title
//   description {
//     description
//   }
//   slug
//   image {
//     file {
//       url
//     }
//   }
// }

export default InsightsSection;
