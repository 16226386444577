import React, {useState, useEffect} from 'react';
import {useLocation} from '@reach/router';
import {initializeAndTrack} from 'gatsby-plugin-gdpr-cookies';

import * as Styled from './CookieConsent.styles.js';
import {
  isBrowser,
  useStickyState,
} from '../../hooks/useStickyState.js';
import {Link} from 'gatsby';

const CookieConsent = () => {
  const [shouldRender, setShouldRender] = useState(false);

  const location = useLocation();
  if (isBrowser()) {
    initializeAndTrack(location);
  }

  const [bannerHidden, setBannerHidden] = useStickyState(
    false,
    'consentCookieHidden'
  );

  const EnableAnalytics = () => {
    document.cookie = 'gatsby-gdpr-google-analytics=true';
    setBannerHidden(true);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShouldRender(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {!bannerHidden && (
        <Styled.CookieBanner
          className={`fade-in ${shouldRender ? 'visible' : 'hidden'}`}
        >
          <Styled.CookieBannerText>
            We use cookies to ensure you get the best experience <Link to="/privacy-policy">on
            our website.</Link>
          </Styled.CookieBannerText>
          <Styled.CookieBannerBtn onClick={EnableAnalytics}>
            Got it
          </Styled.CookieBannerBtn>
        </Styled.CookieBanner>
      )}
    </>
  );
};

export default CookieConsent;
