import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const Wrapper = styled.footer`
  padding: 40px 0;
  background-color: #0b1a17;
  color: #fff;

  ${media.tablet} {
    margin-top: 20px;
    padding: 40px 30px;
  }

  .theme-dark & {
    background-color: #030303;
  }
`;

export const Logo = styled.div`
  text-align: center;
  margin-bottom: 30px;

  ${media.tablet} {
    text-align: initial;
    margin-bottom: 0;
  }

  svg {
    color: #fff;
    transform: scale(1) !important;
  }
`;

export const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Navigation = styled.div`
  ${media.tablet} {
  }
`;

export const Social = styled.a`
  /* margin-top: 30px; */
  margin-bottom: 16px;
  display: block;
  color: #fff;

  ${media.tablet} {
    margin-top: 0;
  }
`;

export const FooterAddress = styled.div`
  margin-bottom: 8px;
  color: #fff;
  .parent > & {
    font-weight: 700;
  }
`;

export const FooterLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.73px;
  color: #fff;
  transition: color 0.2s;
  margin-bottom: 16px;

  &.group {
    color: inherit !important;
    cursor: default;
  }

  &:hover {
    color: ${({theme}) => theme.colors.accent};
  }

  .parent > & {
    font-weight: 700;
  }

  ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`;
