import styled from 'styled-components';

import media from '../../shared/media';

export const InsightsWrapper = styled.section`
  padding: 60px 0;

  ${media.tablet} {
    padding: 110px 30px;
  }

  ${media.desktop} {
    padding: 110px 0;
  }
`;

export const InsightsList = styled.ul`
  list-style: none;
  padding: 0;

  ${media.tablet} {
    display: flex;
    align-items: flex-start;
    margin: 0 -20px;
  }
`;

export const Insight = styled.li`
  padding: 0 20px;

  & + & {
    margin-top: 2rem;
    ${media.tablet} {
      margin-top: 0;
    }
  }
`;

export const InsightImage = styled.div`
  height: 180px;
  margin-bottom: 0.75rem;

  ${media.tablet} {
    height: 214px;
    margin-bottom: 1rem;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InsightTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0 0 0.5rem;
  color: ${({theme}) => theme.colors.textColor};

  ${media.tablet} {
    font-size: 24px;
    letter-spacing: -0.66px;
    margin: 0 0 1em;
  }
`;

export const InsightDescription = styled.div`
  font-size: 16px;
  font-weight: 300;
  line-height: 1.2;
  color: ${({theme}) => theme.colors.textColor};

  ${media.tablet} {
    letter-spacing: -0.66px;
  }

  p {
    margin: 0;
  }
`;
