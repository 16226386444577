import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import Container from '../Container';
import Section from '../Section';
import * as Styled from './TestimonialsSection.styles';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';
import {graphql} from 'gatsby';

const TestimonialsSection = ({testimonials}) => {
  return (
    <Styled.Wrapper>
      <Container>
        {testimonials.map((testimonial) => (
          <Styled.Testimonial>
            <Styled.TestimonialClient>
              <Styled.TestimonialClientLogo>
                <img
                  src={testimonial.logo.file.url}
                  alt={testimonial.name}
                  loading="lazy"
                />
              </Styled.TestimonialClientLogo>
              <Styled.TestimonialClientInfo>
                <Styled.TestimonialClientName>
                  {testimonial.name}
                </Styled.TestimonialClientName>
                <Styled.TestimonialClientRole>
                  <p>{testimonial.role}</p>
                </Styled.TestimonialClientRole>
              </Styled.TestimonialClientInfo>
            </Styled.TestimonialClient>

            <Styled.TestimonialClientComment
              dangerouslySetInnerHTML={{
                __html:
                  testimonial.shortDescription.childMarkdownRemark
                    .html,
              }}
            ></Styled.TestimonialClientComment>
          </Styled.Testimonial>
        ))}
      </Container>
    </Styled.Wrapper>
  );
};

export const query = graphql`
  fragment TestimonialsSection on ContentfulClientsSection {
    slug
    hasLink
    sectionTitle: title
    subtitle {
      subtitle
      childMarkdownRemark {
        html
      }
    }
    testimonials {
      image {
        file {
          url
        }
      }
      name
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      role
      logo {
        file {
          url
        }
      }
    }
  }
`;

export default TestimonialsSection;
