import styled from 'styled-components';

export const Button = styled.button`
  display: inline-block;
  border: 1px solid ${({theme}) => theme.button.borderColor};
  background: transparent;
  outline: none;
  padding: 1rem 2rem;
  cursor: pointer;
  line-height: 1;
  color: ${({theme}) => theme.button.textColor};
  text-decoration: none;
  transition: 0.2s;

  &:hover {
    background-color: ${({theme}) => theme.button.hover.bgColor};
    color: ${({theme}) => theme.button.hover.textColor};
  }
`;
