import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.section`
  position: relative;
  padding-top: 80px;

  ${media.tablet} {
    padding-top: 100px;
  }

  ${media.desktop} {
    padding-top: 120px;
  }

  #banner-image & {
    padding: 120px 0;
    ${media.tablet} {
      margin-top: 40px;
      padding: 180px 0 !important;
    }
  }
`;

export const Title = styled.div``;

export const Background = styled.div`
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: filter 0.2s;

  .theme-dark .section-image-inverted & {
    filter: invert(1);
  }

  #banner-image & {
    text-align: center;
  }

  #banner-image & img {
    object-fit: contain !important;
    width: 90% !important;
  }
`;

export const Body = styled.div`
  margin-top: 27px;
`;
