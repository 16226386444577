import styled from 'styled-components';
import media from '../../shared/media';

export const Wrapper = styled.header`
  padding: 41px 36px;

  .layout-template-services & {
    ${media.tablet} {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 10;
    }
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;

export const Branding = styled.div`
  transition: 0.2s;
  transform: translateX(0);

  .header-navigation-expanded & {
    transform: translateX(30px);
  }
  transform: scale(0.95);
  margin: 0 auto;

  ${media.tablet} {
    margin: unset;
    transform: scale(0.9);
  }
`;

export const Navigation = styled.div`
  margin-left: auto;
  display: none;

  ${media.tablet} {
    display: block;
  }
  .header-navigation-expanded & {
    display: block;
  }
`;

export const CTA = styled.div`
  display: none;
  margin-left: 40px;

  ${media.tablet} {
    display: block;
  }
`;

export const ThemeSwitcher = styled.div`
  /* margin-left: 24px; */

  ${media.tablet} {
    margin-left: 40px;
  }
`;

export const NavigationToggle = styled.button`
  display: inline-block;
  border: none;
  outline: none;
  background: none;
  position: absolute;
  top: 55px;
  left: 20px;
  width: 24px;
  height: 20px;
  z-index: 1001;

  ${media.tablet} {
    display: none;
  }

  span {
    position: absolute;
    top: 6px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({theme}) => theme.colors.textColor};

    .header-navigation-expanded & {
      background-color: transparent;
      position: fixed;
      left: 20px;
      top: 60px;
    }
  }

  span:before,
  span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 2px;
    width: 100%;
    max-width: 24px;
    background-color: ${({theme}) => theme.colors.textColor};
    transition: 0.2s;
  }

  span:before {
    transform: rotate(0) translateY(-6px);

    .header-navigation-expanded & {
      transform: rotate(45deg) translateY(0);
    }
  }

  span:after {
    transform: rotate(0) translateY(6px);

    .header-navigation-expanded & {
      transform: rotate(-45deg) translateY(0);
    }
  }
`;
