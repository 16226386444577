import React from 'react';
import PropTypes from 'prop-types';
import {GatsbyImage} from 'gatsby-plugin-image';

import * as Styled from './Section.styles';
import SectionTitle from '../SectionTitle';
import {graphql} from 'gatsby';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';

const Section = ({
  children,
  className,
  wrapperStyle,
  backgroundImage,
  darkThemeBackgroundImage,
  objectFit = 'cover',
  usePlaceholder = true,
  imageLoading = 'lazy',
  imageInvertOnDark = false,
  id = '',
}) => {
  const {isDark} = useStyledDarkMode();
  return (
    <Styled.Wrapper
      style={wrapperStyle}
      id={id}
      className={[
        className,
        `${imageInvertOnDark ? 'section-image-inverted' : ''}`,
      ].join(' ')}
    >
      {children}
      {backgroundImage && <Styled.Background>
        {typeof backgroundImage === 'string' ? (
          <img
            src={isDark ? darkThemeBackgroundImage : backgroundImage}
            style={{
              objectFit,
              objectPosition: 'center',
              width: '100%',
              height: '100%',
            }}
            alt={'Image banner with text'}
            loading="lazy"
          />
        ) : (
          <GatsbyImage
            image={backgroundImage}
            objectFit={objectFit}
            layout="fullWidth"
            loading={imageLoading}
            placeholder={usePlaceholder ? 'blurred' : 'none'}
            alt={'Image banner with text'}
          />
        )}
      </Styled.Background>}
    </Styled.Wrapper>
  );
};

Section.Title = SectionTitle;
Section.Body = Styled.Body;

Section.propTypes = {
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
  backgroundImage: PropTypes.any,
  objectFit: PropTypes.oneOf(['cover', 'contain']),
  imageLoading: PropTypes.oneOf(['lazy', 'eager']),
  usePlaceholder: PropTypes.bool,
  imageInvertOnDark: PropTypes.bool,
};

export default Section;

export const query = graphql`
  fragment Section on ContentfulSection {
    type
    sectionTitle: title
    subtitle {
      subtitle
      childMarkdownRemark {
        html
      }
    }
    backgroundImage {
      file {
        url
      }
      gatsbyImageData(layout: FULL_WIDTH)
    }

    darkThemeBackgroundImage {
      file {
        url
      }
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
`;
