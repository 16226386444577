import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const Wrapper = styled.div``;

export const ClientsWrapper = styled.div`
  /* padding: 0 20px 0; */

  ${media.tablet} {
    padding: 0;
    border: 1px solid ${({theme}) => theme.colors.lightGray};
    border-width: 1px 0 1px 0;
  }
`;

export const ClientsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 -1px;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  border-width: 1px 1px 0 0;

  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    border-width: 0 1px 0 0;
  }
`;

export const ClientListItem = styled.li`
  flex-basis: 25%;
  flex-grow: 1;
  padding: 20px 34px;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  border-width: 0 0 1px 1px;

  ${media.tablet} {
    padding: 36px 34px;
  }

  &:last-child {
    border-right-width: 1px;
  }
`;

export const ClientLogoWrapper = styled.div`
  height: 50px;
`;

export const ClientLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: filter 0.2s;

  .theme-dark & {
    filter: invert(0.6);
  }
`;

export const ServicesWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  border-width: 0 1px;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
  margin-bottom: 40px;
`;

export const ServicesList = styled.div`
  > div:nth-of-type(even) > div:last-of-type {
    order: 1;
  }
  > div:nth-of-type(even) > div:first-of-type {
    order: 2;
  }
`;

export const ServiceTitle = styled.h3`
  font-size: 1.6rem;
  font-family: Noto Serif;
  font-weight: 600;
  line-height: 1.2;
  color: ${({theme}) => theme.colors.textColor};
  margin: 0 0 10px;

  ${media.tablet} {
    font-size: 40px;
    letter-spacing: -1.5px;
  }

  .theme-dark & {
    color: #fff;
  }
`;

export const ServiceLearnMore = styled.div`
  a {
    display: inline;
    text-decoration: none;
    font-size: 1.2rem;
    color: ${({theme}) => theme.colors.textColor};
    transition: color 0.2s;

    ${media.tablet} {
      font-size: 24px;
    }

    > .icon {
      margin-left: 5px;
      padding-top: 2px;
      vertical-align: middle;
    }

    &:hover {
      color: ${({theme}) => theme.colors.accent};
    }
  }
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 30px;
  transition: 0.2s;

  ${media.tablet} {
    flex-direction: row;
    padding: 60px 70px;
    align-items: stretch;
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 0px;
    height: 0;
    background-color: transparent;
    transition: 0.2s;
  }

  &:hover:before {
    background-color: ${({theme}) => theme.colors.accent};
    height: 88px;
    width: 5px;
  }

  &:hover ${ServiceLearnMore} a {
    color: ${({theme}) => theme.colors.accent};
  }
`;

export const ServiceDescription = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${({theme}) => theme.colors.textColor};
  line-height: 1.4;
  margin: 0 0 10px;
  max-width: ${({fullWidth}) => (fullWidth ? '100%' : '18.7em')};

  ${media.tablet} {
    font-size: ${(props) => (props.isECPage ? '18px' : '24px')};
    letter-spacing: -0.5px;
  }
`;

export const ServiceDetails = styled.div`
  flex-basis: ${({fullWidth}) => (fullWidth ? '100%' : '50%')};
`;

export const ServiceImage = styled.div`
  order: -1;
  padding: 20px 0;

  ${media.tablet} {
    order: initial;
    flex-basis: 50%;
    padding: 0 45px 0 45px;
    height: 100%;
    align-self: center;
  }

  img {
    width: 80%;
    height: 100%;
    object-fit: contain;

    ${media.tablet} {
      width: 100%;
    }
  }
`;

export const ServiceLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
`;
