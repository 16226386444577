import React, {useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import PropTypes from 'prop-types';

import * as Styled from './Header.styles';
import Logo from '../Logo';
import Navigation from '../Navigation';
import Button from '../Button';
import ThemeSwitcher from '../ThemeSwitcher';
import {buildNavigationMenu} from '../../utils/navigation';

const Header = () => {
  const [isNavigationExpanded, setIsNavigationExpanded] =
    useState(false);

  const {contentfulGeneralSettings} = useStaticQuery(graphql`
    fragment MenuItem on ContentfulMenuItem {
      title
      link
      page {
        __typename
        ... on ContentfulPage {
          slug
        }
        ... on ContentfulService {
          slug
        }
        ... on ContentfulInsight {
          slug
        }
        ... on ContentfulService {
          slug
        }
      }
    }
    query {
      contentfulGeneralSettings {
        headerMenu {
          items {
            title
            link
            page {
              __typename
              ... on ContentfulPage {
                slug
              }
              ... on ContentfulService {
                slug
              }
              ... on ContentfulInsight {
                slug
              }
              ... on ContentfulService {
                slug
              }
            }
            childItems {
              ...MenuItem
            }
          }
        }
      }
    }
  `);

  const headerMenu = buildNavigationMenu(
    contentfulGeneralSettings.headerMenu.items
  );

  return (
    <Styled.Wrapper
      className={[
        isNavigationExpanded ? 'header-navigation-expanded' : '',
      ].join(' ')}
    >
      <Styled.Inner>
        <Styled.NavigationToggle
          onClick={() => setIsNavigationExpanded((prev) => !prev)}
          aria-label="Menu toggle"
        >
          <span />
        </Styled.NavigationToggle>
        <Styled.Branding>
          <Logo />
        </Styled.Branding>
        <Styled.Navigation>
          <Navigation
            links={headerMenu}
            onHideNavigation={() => setIsNavigationExpanded(false)}
          />
        </Styled.Navigation>
        <Styled.CTA>
          <Button link="/contact">Let's talk</Button>
        </Styled.CTA>
        <Styled.ThemeSwitcher>
          <ThemeSwitcher />
        </Styled.ThemeSwitcher>
      </Styled.Inner>
    </Styled.Wrapper>
  );
};

Header.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      isExternal: PropTypes.bool,
    })
  ),
};

export default Header;
