import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const Wrapper = styled.div``;

export const FormGroup = styled.div`
  flex: 0 1 50%;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: ${({theme}) => theme.colors.textColor};
`;

export const InputWrapper = styled.div`
  position: relative;
  margin-top: 8px;

  svg {
    position: absolute;
    top: 0;
    left: 16px;
    height: 100%;
  }
`;

export const Input = styled.input`
  max-width: 450px;
  width: 100%;
  height: 48px;
  background-color: transparent;
  color: ${({theme}) => theme.colors.textColor};
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  padding: 0 16px 0 48px;
  cursor: pointer;
`;

export const Select = styled.select`
  max-width: 450px;
  width: 100%;
  height: 48px;
  background-color: transparent;
  color: ${({theme}) => theme.colors.textColor};
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  padding: 0 16px;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  cursor: pointer;
`;

export const ClientsWrapper = styled.div`
  /* padding: 0 20px 0; */

  ${media.tablet} {
    padding: 0;
    border: 1px solid ${({theme}) => theme.colors.lightGray};
    border-width: 1px 0 1px 0;
  }
`;

export const ClientsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 -1px;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  border-width: 1px 1px 0 0;

  ${media.tablet} {
    display: flex;
    flex-wrap: wrap;
    border-width: 0 1px 0 0;
  }
`;

export const ClientListItem = styled.li`
  flex-basis: 25%;
  flex-grow: 1;
  padding: 20px 34px;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  border-width: 0 0 1px 1px;

  ${media.tablet} {
    padding: 36px 34px;
  }

  &:last-child {
    border-right-width: 1px;
  }
`;

export const ClientLogoWrapper = styled.div`
  height: 50px;
`;

export const ClientLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transition: filter 0.2s;

  .theme-dark & {
    filter: invert(0.6);
  }
`;

export const ServicesWrapper = styled.div`
  margin-bottom: 40px;
`;

export const ServicesList = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 16px;

  ${media.tablet} {
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`;

export const ServiceTitle = styled.h3`
  font-size: 1.6rem;
  font-family: Noto Serif;
  font-weight: 600;
  line-height: 1.2;
  color: ${({theme}) => theme.colors.textColor};
  margin: 0 0 10px;

  .theme-dark & {
    color: #fff;
  }
`;

export const ServiceLearnMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
  margin-top: auto;
  color: ${({theme}) => theme.colors.textColor};

  a {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    font-size: 1rem;
    color: ${({theme}) => theme.colors.textColor};
    transition: color 0.2s;
    font-weight: 400;

    

    > .icon {
      margin-left: 5px;
    }
  }

  .insight-date {
    margin-left: auto;
  }
`;

export const ServiceBanner = styled.div`
  flex: 0 1 50%;
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export const Service = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;
  transition: 0.2s;
  border: 1px solid ${({theme}) => theme.colors.lightGray};

  ${media.tablet} {
    flex-direction: row;
    align-items: stretch;
  }

  &:hover ${ServiceLearnMore} a {
    color: ${({theme}) => theme.colors.accent};
  }

  &:hover ${ServiceLearnMore} a .icon path {
    stroke: ${({theme}) => theme.colors.accent};
  }
`;

export const ServiceDescription = styled.div`
  font-size: 1.2rem;
  font-weight: 300;
  color: ${({theme}) => theme.colors.textColor};
  line-height: 1.4;
  margin: 0 0 10px;
  max-width: ${({fullWidth}) => (fullWidth ? '100%' : '18.7em')};
`;

export const ServiceDetails = styled.div`
  flex-basis: ${({fullWidth}) => (fullWidth ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
`;

export const ServiceImage = styled.div`
  order: -1;
  padding: 20px 0;

  ${media.tablet} {
    order: initial;
    flex-basis: 50%;
    padding: 0 45px 0 45px;
    height: 100%;
    align-self: center;
  }

  img {
    width: 80%;
    height: 100%;
    object-fit: contain;

    ${media.tablet} {
      width: 100%;
    }
  }
`;

export const ServiceLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-decoration: none;
`;

export const ServiceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: unset;

  &:nth-child(even) ${ServiceBanner} {
    order: 1;
  }

  &:nth-child(odd) ${ServiceBanner} {
    order: 0;
  }

  ${media.tablet} {
    flex-direction: row;
    grid-column: ${({isBanner}) => (isBanner ? '1/3' : 'unset')};
  }

  ${Service} {
    flex: ${({isBanner}) => (isBanner ? '0 1 50%' : 'unset')};
  }

  ${ServiceTitle} {
    ${media.tablet} {
      letter-spacing: -1.5px;

      font-size: ${({isBanner}) => (isBanner ? '32px' : '20px')};
      font-weight: ${({isBanner}) => (isBanner ? '500' : '600')};
      line-height: ${({isBanner}) => (isBanner ? '36px' : '28px')};
    }
  }

  ${ServiceDescription} {
    ${media.tablet} {
      margin-top: ${({isBanner}) => (isBanner ? '24px' : '16px')};
      font-size: ${({isBanner}) => (isBanner ? '20px' : '18px')};
      line-height: ${({isBanner}) => (isBanner ? '28px' : '24px')};
      letter-spacing: -0.5px;
    }
  }
`;
