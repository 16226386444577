import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';

const Seo = ({title, metaContent, siteUrl, slug}) => {
  const {metaTitle, metaDescription, metaKeywords} = metaContent;

  const removeTags = (str) => {
    return str.replace(/<\/?p[^>]*>/g, '');
  };

  const url = siteUrl + '/' + slug;
  return (
    <Helmet title={metaTitle ?? title}>
      <meta property="og:title" content={metaTitle ?? title} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />

      <meta
        property="og:description"
        content={metaDescription ? removeTags(metaDescription) : ''}
      />

      <meta property="twitter:card" content="website" />
      <meta property="twitter:title" content={metaTitle ?? title} />
      <meta
        property="twitter:description"
        content={metaDescription ? removeTags(metaDescription) : ''}
      />
      

      <meta
        name="description"
        content={metaDescription ? removeTags(metaDescription) : ''}
      />
      <meta
        name="keywords"
        content={metaKeywords ? metaKeywords.join(', ') : ''}
      />
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string,
};

export default Seo;
