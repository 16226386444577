import React from 'react';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';
import * as Styled from './ThemeSwitcher.styles';

const ThemeSwitcher = () => {
  const {isDark, toggleTheme} = useStyledDarkMode();
  return (
    <Styled.Wrapper onClick={toggleTheme} aria-label="Theme switcher">
      {isDark ? (
        <svg
          width="15"
          height="15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 6.875h1.875v1.25H0v-1.25zm1.754-4.236l.884-.885 1.326 1.327-.884.883L1.755 2.64zM6.875 0h1.25v1.875h-1.25V0zm4.16 3.08l1.326-1.326.884.885-1.326 1.325-.883-.883zm2.09 3.795H15v1.25h-1.875v-1.25zm-2.09 5.044l.884-.883 1.327 1.325-.885.885-1.325-1.327zm-4.16 1.206h1.25V15h-1.25v-1.875zm-5.12-.764l1.326-1.325.883.883-1.326 1.327-.884-.885zm5.745-.486a4.375 4.375 0 110-8.75 4.375 4.375 0 010 8.75z"
            fill="currentColor"
          />
        </svg>
      ) : (
        <svg
          width="15"
          height="15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.91 8.48a6.724 6.724 0 01-1.637.205 6.11 6.11 0 01-6.137-6.137c0-.545.069-1.09.205-1.636.068-.205 0-.477-.205-.682-.204-.204-.409-.273-.681-.204C2.25.98 0 3.98 0 7.32c0 4.16 3.34 7.5 7.5 7.5 3.34 0 6.34-2.25 7.227-5.523.069-.204 0-.477-.204-.682a.654.654 0 00-.614-.136z"
            fill="currentColor"
          />
        </svg>
      )}
    </Styled.Wrapper>
  );
};

export default ThemeSwitcher;
