import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './FooterNavigation.styles';

const FooterNavigation = ({navigation = []}) => {
  return (
    <>
      {navigation.map((navigationParent, key) => (
        <Styled.Section key={key}>
          <Styled.Navigation>
            <Styled.NavigationItem className="parent">
              {navigationParent.childItems &&
              navigationParent.childItems.length ? (
                <Styled.NavigationLink as="span" className="group">
                  {navigationParent.title}
                </Styled.NavigationLink>
              ) : (
                <Styled.NavigationLink to={navigationParent.to}>
                  {navigationParent.title}
                </Styled.NavigationLink>
              )}
            </Styled.NavigationItem>
            {(navigationParent.childItems || []).map(
              (navigationChild, key) => (
                <Styled.NavigationItem key={key}>
                  <Styled.NavigationLink to={navigationChild.to}>
                    {navigationChild.title}
                  </Styled.NavigationLink>
                </Styled.NavigationItem>
              )
            )}
          </Styled.Navigation>
        </Styled.Section>
      ))}
    </>
  );
};

FooterNavigation.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      childItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default FooterNavigation;
