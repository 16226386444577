import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.section`
  position: relative;
  border: 1px solid ${({theme}) => theme.colors.lightGray};
  transition: border-color 0.2s;
  padding: 30px 20px;
  margin: 60px 0 30px;
  overflow: hidden;

  ${media.tablet} {
    padding: 58px 36px;
    margin-bottom: 0px;
  }

  &:after {
    content: '';
    position: absolute;
    width: 340px;
    height: 260px;
    right: -48px;
    bottom: -40px;
    background-image: url("data:image/svg+xml,%3Csvg width='342' height='260' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M122.782 99.348l11.95 14.06M126.472 89.748l20.37 23.96M138.952 90.488l6.75 7.95M42.482 59.408a64.606 64.606 0 016.56-6.35M26.912 97.398a58.58 58.58 0 0111.72-33.29' stroke='%23595959' stroke-width='.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M262.232 95.748c.17-21.72-9.75-41.8-26.55-57.88l-27.71-19.67c-21.02-11.03-46.74-17.62-74.55-17.83-71.55-.55-129.87 41.24-130.3 93.39-.1 16.95 5.92 32.92 16.56 46.8a3.38 3.38 0 01.31 3.58l-19 38.79c-.43.88.27 1.91 1.13 1.68l18.22-4.84 31.58-10a4.561 4.561 0 013.57.38c21.39 11.7 47.72 18.78 76.48 19m130.26-93.4c-.062 8.166-1.546 16.081-4.28 23.62m4.28-23.62c-.373 48.668-51.229 88.394-116.196 92.967m-14.064.433a179.3 179.3 0 0013.441-.39m-13.441.39c4.751.04 9.443-.107 14.064-.433m-.623.043c.3 39 44.199 71.39 97.699 70.98 21.51-.16 41.2-5.46 57.19-14.23a3.372 3.372 0 012.67-.27l23.62 7.46 13.61 3.62a.84.84 0 00.85-1.26l-14.2-29a2.511 2.511 0 01.23-2.67c8-10.35 12.46-22.3 12.36-35-.269-34.983-35.646-63.765-81.49-69.02m-112.539 69.39l.623-.043m111.916-69.347c-13.557 37.39-57.85 65.541-111.916 69.347' stroke='%23595959' stroke-width='.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    background-position: 108% 200%;
    background-repeat: no-repeat;
    pointer-events: none;
    transition: filter 0.2s;
    .theme-dark & {
      filter: invert(1);
    }
  }
`;

export const Title = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.2;
  color: ${({theme}) => theme.colors.textColor};
  transition: color 0.2s;
  margin: 0;

  ${media.tablet} {
    font-size: 46px;
    letter-spacing: -2px;
    max-width: 15.4em;
  }
`;

export const CTA = styled.div`
  margin-top: 24px;
`;
