import React from 'react';

import Container from '../Container';
import Section from '../Section';
import * as Styled from './TestimonialsList.styles';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';
import {graphql} from 'gatsby';

const TestimonialsList = ({title, slug, subtitle, testimonials}) => {
  return (
    <Styled.Wrapper>
      <Container>
        <Styled.Title>
          <span>{title}</span>
        </Styled.Title>

        <Styled.TestimonialList>
          {testimonials.map((testimonial) => (
            <Styled.Testimonial>
              <Styled.TestimonialClientLogo>
                <img
                  src={testimonial.logo.file.url}
                  alt={testimonial.name}
                  loading="lazy"
                  width="100"
                  height="30"
                />
              </Styled.TestimonialClientLogo>
              <Styled.TestimonialClientComment
                dangerouslySetInnerHTML={{
                  __html:
                    testimonial.shortDescription.childMarkdownRemark
                      .html,
                }}
              />

              <Styled.TestimonialClient>
                <Styled.TestimonialClientInfo>
                  <Styled.TestimonialClientName>
                    {testimonial.name}
                  </Styled.TestimonialClientName>
                  <Styled.TestimonialClientRole>
                    <p>{testimonial.role}</p>
                  </Styled.TestimonialClientRole>
                </Styled.TestimonialClientInfo>
              </Styled.TestimonialClient>
            </Styled.Testimonial>
          ))}
        </Styled.TestimonialList>
      </Container>
    </Styled.Wrapper>
  );
};

export const query = graphql`
  fragment TestimonialsList on ContentfulClientsSection {
    slug
    hasLink
    sectionTitle: title
    subtitle {
      subtitle
      childMarkdownRemark {
        html
      }
    }
    testimonials {
      image {
        file {
          url
        }
      }
      name
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      role
      logo {
        file {
          url
        }
      }
    }
  }
`;

export default TestimonialsList;
