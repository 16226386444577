import styled from 'styled-components';

import media from '../../shared/media';

export const Wrapper = styled.header`
  padding-top: 80px;
  padding-bottom: 40px;

  ${media.tablet} {
    padding-top: 100px;
  }

  ${media.desktop} {
    padding-top: 120px;
  }
`;

export const FactWrapper = styled.div`
  border: 1px solid ${({theme}) => theme.colors.lightGray};
`;

export const FactTitle = styled.h4`
  text-align: center;
  font-family: 'Noto Serif';
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.5px;
  margin: 0;
  padding: 20px;
  border-bottom: 1px solid ${({theme}) => theme.colors.lightGray};
  color: ${({theme}) => theme.colors.textColor};
`;

export const FactContent = styled.div`
  font-family: 'Source Sans Pro';
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  padding: 20px;
  color: ${({theme}) => theme.colors.textColor};
`;
