import React from 'react';

import * as Styled from './Container.styles';

const Container = ({children, pageContext}) => {
  const maxWidth = (pageContext?.slug.includes('insights/') ? 'max-w-640' : '')
  return <Styled.Container className={[`${maxWidth}`].join(' ')}>{children}</Styled.Container>;
};

export default Container;
