import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './Footer.styles';
import Container from '../Container';
import Logo from '../Logo';
import LinkedInLogoLight from '../../assets/svg/logos/linkedin-light.svg';

import FooterNavigation from './FooterNavigation';
import CookieConsent from '../../components/CookieConsent';

const Footer = ({navigation, companyAddress}) => {
  return (
    <Styled.Wrapper>
      <Container>
        <Styled.Inner>
          <Styled.Row>
            <Styled.Logo>
              <Logo variant="default" type="light" />
            </Styled.Logo>
            <Styled.FooterAddress
              dangerouslySetInnerHTML={{
                __html:
                  companyAddress.address.childMarkdownRemark.html,
              }}
            />

            <Styled.FooterLink
              href="mailto:hi@appliedlabs.io"
              aria-label={`Email address ${companyAddress.email}`}
            >
              {companyAddress.email}
            </Styled.FooterLink>
            <Styled.Social
              href="https://www.linkedin.com/company/applied-labs-io"
              aria-label="LinkedIn"
            >
              <LinkedInLogoLight />
            </Styled.Social>
          </Styled.Row>
          <FooterNavigation navigation={navigation} />
        </Styled.Inner>
      </Container>
      <CookieConsent />
    </Styled.Wrapper>
  );
};

Footer.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          to: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

export default Footer;
