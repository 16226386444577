import {useContext, useCallback} from 'react';
import {ThemeManagerContext} from '../ThemeManager';

export const useStyledDarkMode = () => {
  const {isDark, changeThemeSetting, toggleDark, themeSetting} =
    useContext(ThemeManagerContext);

  const handleToggleTheme = useCallback(() => {
    toggleDark(!isDark);
  }, [toggleDark, isDark]);

  return {
    isDark,
    changeThemeSetting,
    // toggleDark,
    toggleTheme: handleToggleTheme,
    themeSetting,
  };
};
