import React from 'react';

import Container from '../Container';
import * as Styled from './CardsSection.styles';
import {graphql} from 'gatsby';

import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';

const CardsSection = ({
  slug,
  title,
  subtitle,
  cards,
  hasLink,
  linkLabel = 'Learn more',
}) => {
  function isSvg(url) {
    return /\.(svg)$/.test(url);
  }
  const {isDark} = useStyledDarkMode();

  return (
    <Styled.Wrapper>
      <Container>
        <Styled.Title>{title}</Styled.Title>
        {subtitle ? (
          <Styled.Subtitle
            dangerouslySetInnerHTML={{
              __html: subtitle
                ? subtitle.childMarkdownRemark.html
                : '',
            }}
          />
        ) : (
          ''
        )}
        <Styled.Content>
          {cards.map((card, id) => (
            <Styled.Card key={id}>
              <Styled.CardImage>
                <img
                  src={
                    isDark && isSvg(card.image.file.url)
                      ? card.darkModeImage.file.url
                      : card.image.file.url
                  }
                  alt={card.title}
                  loading="lazy"
                  width="280"
                  height="200"
                />
              </Styled.CardImage>
              <Styled.CardTitle>{card.title}</Styled.CardTitle>

              <Styled.CardText>
                {card.shortDescription.shortDescription}
              </Styled.CardText>

              {hasLink === true ? (
                <Styled.CardLink to={'/services/' + card.slug} aria-label="services content learn more">
                  <span className='visibility-hidden'>{card.title}</span>
                  <span aria-label={card.title}>{linkLabel}</span>
                  <span className="icon">
                    <svg
                      width="23"
                      height="24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.094 11.468l-8.301-7.205a.353.353 0 00-.23-.085H8.617a.175.175 0 00-.115.307l7.695 6.68H3.34a.176.176 0 00-.176.176v1.318c0 .097.08.176.176.176h12.856l-7.695 6.68a.175.175 0 00.114.307h2.011a.169.169 0 00.114-.044l8.354-7.246a.704.704 0 000-1.064z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </Styled.CardLink>
              ) : (
                ''
              )}
            </Styled.Card>
          ))}
        </Styled.Content>
      </Container>
    </Styled.Wrapper>
  );
};

export const query = graphql`
  fragment CardsSection on ContentfulInsightsSection {
    sectionTitle: title
    type
    insightsWithLink
    items {
      title
      slug
      shortDescription {
        shortDescription
      }
      image {
        file {
          url
        }
      }
      darkModeImage {
        file {
          url
        }
      }
    }
  }
`;

export default CardsSection;
