import styled from 'styled-components';
import media from '../../shared/media';

export const CookieBanner = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: #78c257;
  padding: 10px 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  ${media.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

export const CookieBannerText = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;

  a {
    color: #0b1a17;
  }
`;

export const CookieBannerBtn = styled.button`
  border: 1px solid #fff;
  padding: 8px;
  background: none;
  color: #fff;
  cursor: pointer;
  display: inline-block;

  outline: none;
  padding: 1rem 2rem;
  line-height: 1;
  text-decoration: none;
  transition: all 0.2s ease 0s;

  &:hover {
    background: #fff;
    color: #000;
  }
`;
