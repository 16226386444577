import styled from 'styled-components';

export const Wrapper = styled.button`
  background: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  border: none;
  line-height: 1;

  svg {
    color: #000;
    transition: color 0.2s;

    .theme-dark & {
      color: #fff;
    }
  }
`;
