import styled from 'styled-components';
import media from '../../shared/media';
import Link from 'gatsby-link';

export const Wrapper = styled.div`
  padding-top: 30px;

  ${media.tablet} {
    padding-top: 60px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  column-gap: 24px;
  row-gap: 40px;

  ${media.tablet} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    row-gap: 100px;
  }
`;


export const CardLink = styled.div`
  text-align: center;
  margin-top: 20px;
  a {
    opacity: 0;
  }
`;

export const Card = styled.div`
  max-width: 450px;
  flex: 0 1 450px;

  margin-bottom: 32px;
  cursor: pointer;
  color: ${({theme}) => theme.colors.textColor};

  ${media.tablet} {
    margin-bottom: 0;
  }

  &:hover ${CardLink} a {
    opacity: 1;
  }
`;

export const CardImage = styled.figure`
  display: block;
  margin: 0;

  img {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
  }
`;

export const CardTitle = styled.p`
  font-family: 'Noto Serif';
  font-size: 24px;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 0;

  ${media.tablet} {
    font-size: 32px;
  }
`;

export const CardText = styled.p`
  margin-top: 26px;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;

  ${media.tablet} {
    font-size: 20px;
    line-height: 24px;
  }
`;

