import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import Section from '../Section';
import * as Styled from './ClientsInsightsSection.styles';
import Container from '../Container';
import {useStyledDarkMode} from '../../hooks/useStyledDarkMode';
import {graphql} from 'gatsby';
import {createdFormattedDate} from '../../utils/formattedDate';
import { readingLength } from '../../utils/readingLength';

const ClientsInsightsSection = ({
  title,
  slug,
  pageSlug,
  hasLink,
  subtitle,
  createdAt,
  clients = [],
  services = [],
  linkLabel = 'Learn more',
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sortTerm, setSortTerm] = useState('');
  const {isDark} = useStyledDarkMode();

  let isNewCSPage = pageSlug === 'case-studies';
  const csLink = isNewCSPage ? '/' : `/${slug || 'services'}/`;

  let allMetaTitles = services
    .map((service) => {
      return service.metaTitle;
    })
    .sort();

  let metaTitles = [...new Set(allMetaTitles)];

  const filteredServices = services.filter((service) => {
    const titleMatch = service.title
      .toLowerCase()
      .includes(searchTerm.toLowerCase());
    const typeMatch =
      sortTerm === '' || service.metaTitle === sortTerm;

    return titleMatch && typeMatch;
  });

  

  return (
    <Section>
      <Container>
        <Section.Title
          id={slug}
          alignment="left"
          title={title}
          subtitle={subtitle ? subtitle.childMarkdownRemark.html : ''}
        />

        <div
          style={{display: 'flex', justifyContent: 'space-between'}}
        >
          <Styled.FormGroup>
            <Styled.Label htmlFor="search">Sort by</Styled.Label>
            <Styled.InputWrapper>
              <Styled.Select
                onChange={(e) => setSortTerm(e.target.value)}
              >
                <option value="" selected>
                  All categories
                </option>
                ;
                {metaTitles.map((item) => {
                  return <option value={item}>{item}</option>;
                })}
              </Styled.Select>
            </Styled.InputWrapper>
          </Styled.FormGroup>

          <Styled.FormGroup>
            <Styled.Label htmlFor="search">
              Find by title
            </Styled.Label>
            <Styled.InputWrapper>
              <Styled.Input
                type="text"
                id="search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="ERP integration..."
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 20L15.9497 15.9497M15.9497 15.9497C17.2165 14.683 18 12.933 18 11C18 7.13401 14.866 4 11 4C7.13401 4 4 7.13401 4 11C4 14.866 7.13401 18 11 18C12.933 18 14.683 17.2165 15.9497 15.9497Z"
                  stroke="#848484"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Styled.InputWrapper>
          </Styled.FormGroup>
        </div>
      </Container>
      <Section.Body>
        {services && (
          <Container>
            <Styled.ServicesWrapper id="services">
              <Styled.ServicesList>
                {filteredServices.map((service, key) => {
                  const serviceIcon =
                    service.icon && service.icon.file
                      ? service.icon.file.url
                      : null;
                  const darkServiceIcon =
                    service.darkThemeIcon &&
                    service.darkThemeIcon.file
                      ? service.darkThemeIcon.file.url
                      : null;
                  const banner =
                    service.banner && service.banner.file
                      ? service.banner.file.url
                      : null;

                  const hasIcon = darkServiceIcon || serviceIcon;

                  return (
                    <Styled.ServiceWrapper isBanner={!!banner}>
                      {banner ? (
                        <Styled.ServiceBanner>
                          <img src={banner} alt={service.title} />
                        </Styled.ServiceBanner>
                      ) : (
                        ''
                      )}
                      <Styled.Service key={key}>
                        {hasLink && (
                          <Styled.ServiceLink
                            to={`${csLink}${service.slug}`}
                            aria-label={`${service.slug}`}
                          />
                        )}
                        <Styled.ServiceDetails fullWidth={!hasIcon}>
                          {!isNewCSPage && (
                            <Styled.ServiceTitle>
                              {service.title}
                            </Styled.ServiceTitle>
                          )}
                          <Styled.ServiceDescription
                            dangerouslySetInnerHTML={{
                              __html:
                                service.shortDescription
                                  .childMarkdownRemark.html,
                            }}
                            isECPage={pageSlug === 'e-commerce'}
                            fullWidth={!hasIcon}
                          />
                          {hasLink && (
                            <Styled.ServiceLearnMore>
                              <Link to={`${csLink}${service.slug}`}>
                                <span>
                                  {csLink?.includes('insights')
                                    ? 'Read more'
                                    : linkLabel}
                                </span>

                                <svg
                                  className="icon"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <path
                                    d="M19 12L13 6M19 12L13 18M19 12H5"
                                    stroke="#0B1A17"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </Link>
                              <span className="insight-date">
                                {createdFormattedDate(
                                  service.createdAt
                                )}
                              </span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="4"
                                height="4"
                                viewBox="0 0 4 4"
                                fill="none"
                              >
                                <circle
                                  cx="2"
                                  cy="2"
                                  r="2"
                                  fill="#0B1A17"
                                />
                              </svg>
                              <span className="insight-length">
                                {readingLength(
                                  service.shortDescription
                                    .childMarkdownRemark.html, 18
                                )}{' '}
                                minutes to read
                              </span>
                            </Styled.ServiceLearnMore>
                          )}
                        </Styled.ServiceDetails>
                        {hasIcon && (
                          <Styled.ServiceImage>
                            {isDark ? (
                              <img
                                src={darkServiceIcon || serviceIcon}
                                style={
                                  isDark && !darkServiceIcon
                                    ? {filter: 'invert(1)'}
                                    : {}
                                }
                                alt={service.title}
                              />
                            ) : (
                              <img
                                src={serviceIcon}
                                alt={service.title}
                              />
                            )}
                          </Styled.ServiceImage>
                        )}
                      </Styled.Service>
                    </Styled.ServiceWrapper>
                  );
                })}
              </Styled.ServicesList>
            </Styled.ServicesWrapper>
          </Container>
        )}
      </Section.Body>
    </Section>
  );
};

export const query = graphql`
  fragment ClientsInsightsSection on ContentfulClientsSection {
    slug
    hasLink
    createdAt
    sectionTitle: title
    subtitle {
      subtitle
      childMarkdownRemark {
        html
      }
    }
    clients {
      title
      logo {
        file {
          url
        }
      }
    }
    services {
      title
      slug
      createdAt
      shortDescription {
        childMarkdownRemark {
          html
        }
      }
      banner {
        file {
          url
        }
      }
      icon {
        file {
          url
        }
      }
      darkThemeIcon {
        file {
          url
        }
      }
      metaTitle
    }
  }
`;

ClientsInsightsSection.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      logo: PropTypes.any,
    })
  ),
};

export default ClientsInsightsSection;
