import React from 'react';
import PropTypes from 'prop-types';

import * as Styled from './SectionContent.styles';
import Container from '../Container';

const SectionContent = ({
  title,
  content,
  alignment = 'left',
  wrapperStyle,
}) => {
  return (
    <Styled.Wrapper
      className={[`section-title-alignment-${alignment}`]}
      style={wrapperStyle}
    >
      <Container>
        <Styled.Title>{title}</Styled.Title>
        {content ? (
          <Styled.Content
            dangerouslySetInnerHTML={{
              __html: content ? content.childMarkdownRemark.html : '',
            }}
          />
        ) : (
          ''
        )}
      </Container>
    </Styled.Wrapper>
  );
};

SectionContent.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  alignment: PropTypes.oneOf(['center', 'left']),
};

export default SectionContent;
