import React from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';

import * as Styled from './Button.styles';

const Button = ({variant = 'primary', className, onClick, link, children}) => {
  return (
    <Styled.Button
      as={link ? Link : 'button'}
      to={link}
      className={[className, `button-variant-${variant}`].join(' ')}
      onClick={onClick}
      aria-label={`Navigate to ${link ?? children}`}
    >
      <span className='visibility-hidden'>check the link</span>
      {children}
    </Styled.Button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.oneOf(['primary', 'secondary']),
  onClick: PropTypes.func,
  link: PropTypes.string,
};

export default Button;
