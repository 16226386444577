import styled from 'styled-components';
import media from '../../shared/media';

export const Container = styled.div`
  max-width: 940px;
  margin: 0 auto;
  padding: 0 20px;

  ${media.tablet} {
    padding: 0;
  }
`;
