import styled from 'styled-components';
import Link from 'gatsby-link';

import media from '../../shared/media';

export const Wrapper = styled.div`
  padding: 0 20px;
  text-align: center;

  ${media.tablet} {
    display: flex;
    padding: 0;
    text-align: initial;
  }

  ${media.mobile} {
    padding: 0;
    margin-bottom: 16px;
    text-align: start;
  }
`;

export const Section = styled.div`
  & + & {
    margin-top: 30px;

    ${media.tablet} {
      margin-left: 80px;
      margin-top: 0;
    }
  }
`;

export const Navigation = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const NavigationItem = styled.li`
  margin-bottom: 8px;
  &.parent {
    margin-bottom: 24px;
  }
`;

export const NavigationLink = styled(Link)`
  text-decoration: none;
  font-size: 16px;
  letter-spacing: -0.73px;
  color: #fff;
  transition: color 0.2s;

  &.group {
    color: inherit !important;
    cursor: default;
  }

  &:hover {
    color: ${({theme}) => theme.colors.accent};
  }

  .parent > & {
    font-weight: 700;
  }
`;
