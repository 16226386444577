import styled from 'styled-components';

import media from '../../shared/media';

export const Logo = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
    color: ${({theme}) => theme.colors.logoColor};
  }
  svg {
    transition: 0.2s;
    color: ${({theme}) => theme.colors.logoColor};
  }
`;
